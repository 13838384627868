<template>
	<div class="useguide_popup">
    	<div class="title_wrap"><h2>이용방법</h2></div>

		<div class="tab_wrap">
			<div class="tab">
				<ul>
					<li v-for="(item, index) in faqTabList" :key="index" :class="tabIdx == index ? 'active' : ''">
						<a href="javascript:void(0);" @click="tabIdxCng(index)"><span>{{item}}</span></a>
					</li>
				</ul>
			</div>

			<div class="tab_cont_group">
				<!-- 수요기업 -->
				<div class="tab_cont" :class="tabIdx == '0' ? 'active' : ''">
					<div class="tab_cont_inner">
						<div class="cont_nav">
							<ul class="cont_nav_list">
								<li v-for="(item, index) in dcoItemList" :key="index" :class="itemIdx == index ? 'active' : ''">
									<a href="javascript:void(0);" @click="itemIdxCng(index)">{{item}}</a>
								</li>
							</ul>
						</div>
						<!-- <div class="cont_detail pl-10">
							<div class="ta-center mt-6 mb-7"><strong class="text_type_title">회원가입 시 계정선택은 어떻게 하나요?</strong></div>
							<div class="cont_image">
								<img src="/images/img_useguide_01.png" alt="프로엔 공통사항 화면 캡쳐" />
							</div>
							<p class="text_type_01 ta-center mt-10">
								수요기업이 하이프로를 사용하기 위해서는 본 서비스를 총괄하는 관리총괄담당자와 개별 프로젝트를 수행하는 <br />
								프로젝트담당자가 회원으로 우선 가입하여야 합니다.
							</p>
						</div> -->

						<!--00 공통사항 -->
						<div class="cont_detail pl-10 pr-10" v-if="itemIdx == '0'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로 인재관리 시스템은 성공적인 프로젝트를 위하여 IT인재에 대한 평가와 검증 및 관리를 통하여 검증된<br />
								IT전문인재들을 기업의 프로젝트와 연결해 주는 온라인 인재관리 플랫폼입니다. <br /><br />

								즉, 용역을 제공하는 IT전문인력(프리랜서 또는 공급업체 기술인력)과 IT인력으로부터 용역을 공급받아 <br />
								프로젝트를 수행하고자 하는 수요기업 및 이들을 매칭해 주는 운영시스템인 하이프로로 구성된 플랫폼을 의미합니다. <br /><br />

								자세한 사항은 하이프로의 약관을 참조하시기 바랍니다.
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스 프로세스는 어떻게 되나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								기술인재, 수요기업, 공급기업간 서비스 연계는 하이프로를 통하여 진행합니다.<br />
								각 주체간에는 계약을 체결함으로써 정보 및 용역서비스가 제공되며, 하이프로의 프로젝트별 담당매니저가 <br />
								각 주체간 중개, 중재, 관리 및 계약을 지원합니다. <br /><br />

								하이프로 서비스의 원활한 이용을 위해서는 담당매니저와의 적극적인 커뮤니케이션이 많은 도움을 드릴 것입니다. 
							</p>
                            <br />
							
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로는 회원의 개인정보를 어떻게 보호하고 있나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="15"/>1) 하이프로는 회원의 개인정보보호를 위하여 노력합니다.<br />
								<nbsp n="15"/>2) 회원의 개인정보보호에 대해서는 관련법령 및 회사가 별도로 고지하는 개인정보처리방침이 적용됩니다.<br />
								<nbsp n="15"/>3) 회원의 탈퇴 시에는 원칙적으로 즉시 개인정보를 완전히 삭제합니다.<br /><nbsp n="20"/>상세한 내용은 하이프로의 개인정보처리방침을 참고하시기 바랍니다.<br />
								<nbsp n="15"/>4) 회사는 회원 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 개인정보, 비밀정보에 대해서는<br /><nbsp n="20"/>책임을 부담하지 않습니다.<br />
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스의 My Page를 이용하시면 편리합니다.</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="30"/>회원 가입 후 My Page를 이용하면 사용이 훨씬 편리해집니다.<br />
								<nbsp n="30"/>① 프로젝트 상세조회 : 수행중 / 수행예정/ 완료 프로젝트 상세 조회<br />
								<nbsp n="30"/>② 관심인재 등록 정보<br />
								<nbsp n="30"/>③ 프로젝트 지원자 정보 <br />
								<nbsp n="30"/>④ 프로젝트 종료 후 미평가자 정보 <br />
								<nbsp n="30"/>⑤ 프로젝트 인터뷰 요청 상태  등 <br />
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스 사용이 가능한 시간은?</strong>
							</div>
							<p class="text_type_01 ta-center">
								원칙적으로 24시간 365일 사용 가능합니다.<br />
								단, 매일 23:00~익일 04:00까지는 시스템 작업 관계로 일부 서비스가 제한될 수 있습니다.<br /><br />

								하이프로 고객센터 운영 : 평일 09:30~17:30 (토,일,공휴일은 휴무)
							</p>
						</div>
						<!--//00 공통사항 -->

						<!--01 회원가입 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '1'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 플랫폼에서 수요기업이란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업은 IT기술인재로부터 용역을 제공받거나 용역제공을 위하여 기술인재를 모집하는 기업을 의미합니다. <br />
								즉, 직접 기술인재로부터 용역을 제공받는 발주사 또는 프로젝트를 수주한 사업자를 의미합니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로에 가입하려고 하는 수요기업정보는 어떻게 등록하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								수요기업정보는 사전에 하이프로와 수요기업간 체결한 서비스 이용계약에 따라 전달받은 정보를 바탕으로<br /> 
								하이프로 운영팀에서 직접 시스템에 등록합니다.<br />
								하이프로는 최신의 수요기업정보를 관리하기 위하여 정기적으로 변경된 수요기업정보를 조사하여 시스템에 반영합니다. 
							</p>
							<br /><br /><br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스는 어떻게 이용할 수 있나요? <br />(하이프로 서비스 이용계약 체결)</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업은 IT기술인재로부터 용역을 제공받거나 용역제공을 위하여 기술인재를 모집하는 기업을 의미합니다. <br />
								즉, 직접 기술인재로부터 용역을 제공받는 발주사 또는 프로젝트를 수주한 사업자를 의미합니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 회원가입 등록은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업의 임직원이 기술인재를 필요로 하는 회사를 대리하여 하이프로 서비스를 사용할 수 있도록 <br />실무담당자가 개인명의 회원으로 가입하여야 합니다. <br />
								이는 프로젝트의 등록, 기술인재의 선발 및 계약 등 실무처리와 효율적인 인력관리를 위한 하이프로의 운영기준입니다.  
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">회원가입 시 계정선택은 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_01_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/syo_01_02.png">
							</div>	
							<br />						
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="10"/>수요기업이 하이프로를 사용하기 위해서는 본 서비스를 총괄하는 관리총괄담당자와 개별 프로젝트를 수행하는 <br />
								<nbsp n="10"/>프로젝트담당자가 회원으로 우선 가입하여야 합니다. <br />
								<nbsp n="10"/>- 관리총괄 : 하이프로와 관련한 인력, 재무, 계약, 협의 등 실무를 총괄하는 담당자로서 먼저 회원 가입을 하여야 <br />
								<nbsp n="13"/>프로젝트담당자의 회원 가입 시 승인처리를 할 수 있습니다. <br />
								<nbsp n="10"/>- 프로젝트담당 : 기술인재를 필요로 하는 개별 프로젝트의 PM 또는 PMO 등 실무담당자로서 기술인재 검색, <br />
								<nbsp n="13"/>인터뷰, 계약, 사후 평가 등 하이프로 서비스 고객의 핵심 역할을 수행하게 됩니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">서비스 구분의 무료와 유료의 의미는 무엇인가요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								수요기업 서비스 이용계약 체결 시 '서비스 구분'에 따라 기술인재에 대한 정보가 다음과 같이 차별적으로 제공됩니다. <br />
								1) 서비스 구분이 '무료'인 경우 : 기술인재에 대한 기본정보만 제공<br />
								<nbsp n="2"/>- 성별, 연령, 경력월수, 관심인재 여부, 평가등급, 기술등급, NCS 자가평가(직무/등급), <br />
								<nbsp n="5"/>전문분야, 직무분야, 보유기술, 수행완료 프로젝트<br />
								2) 서비스 구분이 '유료'인 경우 : 기술인재 기본정보 및 상세정보 <br />
								<nbsp n="2"/>- 기술인재 기본정보<br />
								<nbsp n="2"/>- 자기소개서, 학력, 재직 경력, 프로젝트 경력, 희망근무조건, 프로젝트 수행평가정보
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">유료 서비스 비용은 얼마이며 결제는 언제 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="3"/>1) 유료 서비스 금액<br />
								<nbsp n="5"/>- 기본요금 연간 500,000원 + 인터뷰 진행건당 50,000원<br />
								<nbsp n="5"/>- 예시) 2023-06-25 서비스 이용계약 체결, 2023-06-25 ~ 2024-03-31 중 인터뷰 진행건수 100건<br />
								<nbsp n="7"/> => (500,000원 X 280일/365일) + (50,000원 X 100 건) = 383,561원 + 5,000,000원 = 5,383,561원<br />
								<nbsp n="3"/>2) 비용 정산 시기<br />
								<nbsp n="5"/>- 매년 3월말 기준 정산 및 대금 청구(15일이내 결제)
							</p>
							<br />
						</div>
						<!--//01 회원가입 -->

						<!--02 프로젝트 등록 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '2'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 등록은 반드시 해야 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업에서 외부 기술인재를 통하여 사업을 수행하고자 할 때는 하이프로에 프로젝트 등록을 반드시 하여야 합니다. <br />
								프로젝트를 등록하면 기술인재와 인터뷰 및 계약을 통한 사업 참여 등 프로젝트 팀원으로 확보가 가능하게 됩니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 등록은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								<strong>- 공급기업 지정 프로젝트 등록 -</strong>
							</p>
							<p class="text_type_01 ta-center mb-10">
								공급기업 지정 프로젝트는 하이프로 매니저가 배정되지 않으며,<br />
								수요기업과 공급기업간 직접 거래하는 프로젝트 입니다. <br />
								수요기업에서 프로젝트 등록시 지정한 공급기업에서<br />
								프로젝트 상세 모집정보를 등록하여 기술인재를 모집하는 프로젝트 입니다.
							</p>
							<div class="cont_image">
								<img src="/images/syo_02_01.png">	
							</div>	
							<br />
							<p class="text_type_01 ta-center mb-10">
								1) 로그인 후 ‘공급기업 지정 프로젝트 > 프로젝트 등록’을 클릭합니다.
							</p>
							<div class="cont_image">
								<img src="/images/syo_02_02.png">	
							</div>	
							<p class="text_type_01 ta-center mb-10">
								2) 공급기업 지정 프로젝트 등록 페이지에서 <br />
								‘프로젝트 구분’, ‘프로젝트명’, ‘프로젝트 기간’, '고객사명', '고객사CI', '고객사 산업분야'를 입력합니다.
							</p>
							<div class="cont_image">
								<img src="/images/syo_02_03.png">	
							</div>	
							<div class="cont_image">
								<img src="/images/syo_02_03_02.png">	
							</div>
							<p class="text_type_01 ta-center mb-10">
								3) 마지막으로 ‘등록기업을 지정’하여, 등록완료 합니다.<br />
								등록완료된 프로젝트는 지정된 공급기업에게 등록요청 됩니다.
							</p>
							<br /><br /><br /><br />

							<p class="text_type_01 ta-center mb-10">
								<strong>- 일반 프로젝트 등록 -</strong>
							</p>
							<p class="text_type_01 ta-center mb-10">
								일반 프로젝트는 하이프로 매니저가 배정되며,<br />
								수요기업이 프로젝트 상세 모집정보를 등록하여 기술인재를 모집하는 프로젝트 입니다.
							</p>
							<div class="cont_image">
								<img src="/images/syo_02_04.png">	
							</div>	
							<br />
							<p class="text_type_01 ta-center mb-10">
								1) 로그인 후 프로젝트 ‘일반 프로젝트 > 프로젝트 등록’을 클릭합니다.
							</p>
							<div class="cont_image">
								<img src="/images/syo_02_05.png">	
							</div>	
							<p class="text_type_01 ta-center mb-10">
								2) 일반 프로젝트 등록 페이지에서 프로젝트 정보를 입력하여 등록합니다.<br />
								프로젝트를 등록하면 하이프로 매니저가 프로젝트 관련 사항을 자세하게 도와드립니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 등록비용이 별도로 필요한가요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								프로젝트 등록에 따른 이용료는 별도로 부담하지 않습니다.<br />
								단, 하이프로 프로젝트 담당매니저를 통한 인터뷰 진행 등 <br />투입인력에 대한 관리가 이루어지는 경우에는 별도의 사용료가 부과됩니다  
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 매니저는 누구이며, 어떤 도움을 줄 수 있나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업에서 프로젝트를 등록하면 하이프로에서는 해당 프로젝트에 대하여 금융IT전문의 담당 매니저를 선임합니다. <br />
								매니저는 해당 프로젝트의 등록내용 검토, 기술인재 선발, 인터뷰 진행, 계약, 계약된 기술인재 관리, 프로젝트 진행 자문,	<br />
								프로젝트 종료, 참여한 기술인재 평가 등 프로젝트 준비부터 사후관리까지 지원합니다. <br />
								매니저를 통하여 접수된 이슈 등 지원이 필요한 사항은 하이프로 플랫폼 차원에서 검토하고 지원할 수 있습니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 등록 시 특별히 유의할 사항은 무엇인가요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업의 임직원이 기술인재를 필요로 하는 회사를 대리하여 하이프로 서비스를 사용할 수 있도록 <br />
								실무담당자가 개인명의 회원으로 가입하여야 합니다. <br />
								이는 프로젝트의 등록, 기술인재의 선발 및 계약 등 실무처리와 효율적인 인력관리를 위한 하이프로의 운영기준입니다.  
							</p>
						</div>
						<!--//02 프로젝트 등록 -->

						<!--03 기술인재 찾기 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '3'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 찾기란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								프로젝트 수행에 필요한 기술인재를 검색하는 서비스로서, 프로젝트를 등록한 기업회원담당자에게는 <br />
								기술인재에 대한 이력정보 및 평가정보 등 상세한 정보를 제공합니다.
							</p>
							<br />

							<div class="cont_image">
								<img src="/images/syo_03_01.png">
							</div>
							<br />

							<p class="text_type_01 ta-left mb-10">
								<nbsp n="30"/>1. 상단 고정메뉴 '기술인재찾기'를 클릭하여 조건에 맞는 기술인재에 대한 <br />
								<nbsp n="34"/>상세정보를 조회활 수 있습니다. <br />
								<nbsp n="30"/>2. 또는 좌측 상단의 메뉴목록을 클릭한 후 '기술인재 정보/ 기술인재 찾기'를 통해  <br />
								<nbsp n="34"/>기술인재에 대한 상세정보를 조회할 수 있습니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 상세정보에 대한 자세한 설명이 필요합니다.</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_03_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-left mb-10">
								<nbsp n="43"/>수요기업 회원은 기술인재에 대한 상세정보 확인이 가능합니다.<br />
								<nbsp n="43"/>> 기술인재 평가등급(수행사 평가, 하이프로 평가)<br />
								<nbsp n="43"/>> 기술등급 (학력, 자격)<br />
								<nbsp n="43"/>> NCS 자가평가 (직무/등급)<br />
								<nbsp n="43"/>> 전문분유, 직무분야, 보유기술, 수행완료 프로젝트 <br />
								<nbsp n="43"/>> 상세정보 : 자기소개, 학력, 자격증, 경력, 증빙서류, 수행사 평가 <br />
								<nbsp n="43"/>> 등록 : 프로젝트 인터뷰 요청, 관심인재 등록
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 평가등급은 어떻게 부여합니까?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_03_04.png">
							</div>
							<br />

							<p class="text_type_01 ta-left">
								<nbsp n="35"/>기술인재는 '하이프로 인재 평가기법'을 적용하여 평가등급을 확정합니다. <br />
								<nbsp n="35"/>전체 평가영역(기술경역 + 직무능력 + 수행성과 + 인성평가)을 종합적으로 점검하여 확정합니다. <br />
								<nbsp n="35"/>> 시스템을 통한 1차 자동검증 : 기술 경력(학력, 경력, 자격증)<br />
								<nbsp n="35"/>> 자가평가 : NCS 직무능력 자가평가<br />
								<nbsp n="35"/>> 전문가를 통한 평가 : 하이프로 평가, 수행사 평가 
							</p>
						</div>
						<!--//03 기술인재 찾기 -->

						<!--04 프로젝트 지원인재 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '4'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 지원인재란?</strong>
							</div>
							<br />
							<p class="text_type_01 ta-center">
								프로젝트를 등록한 수요기업에서 해당 프로젝트에 참여를 지원한 기술인재 정보를 조회하는 화면입니다. <br />
								지원한 기술인재를 선택하면 해당 기술인재에 대한 상세보기 화면을 통하여 기술 및 평가등급, <br />
								수행 프로젝트 정보를 확인할 수 있으며, 해당 화면에서 '인터뷰 요청' 등록을 할 수 있습니다. 
							</p>
                            <br /><br /><br /><br />
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트에 지원한 기술인재는 어떻게 확인할 수 있나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_04_01.png">
							</div>
								<div class="cont_image">
								<img src="/images/syo_04_02.png">
							</div>

							<p class="text_type_01 ta-center">
								메뉴목록의 '일반 프로젝트/ 프로젝트 지원인재' 메뉴를 클릭하여 <br />
								프로젝트에 지원한 기술인재 리스트및 지원한 기술인재의 상세정보를 조회활 수 있습니다.
							</p>
							<br />
						</div>
						<!--//04 프로젝트 지원인재 -->

						<!--05 관심인재 등록 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '5'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">관심인재란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								프로젝트를 등록한 수요기업의 프로젝트 담당자는 사업에 필요한 기술인재를 검색한 후 <br />
								인터뷰를 원하는 기술인재를 관심인재로 등록할 수 있으며, <br />
								등록된 관심인재는 '관심인재' 거래화면으로 일괄 조회하여 인터뷰를 요청 할 수 있습니다. <br /><br />
								관심인재는 프로젝트 등록 여부와 관계없이 수요기업회원이 사전에 기술인재를 검색하여 <br />
								필요한 인력을 선정하는 활동으로서 프로젝트에 참여하기 전까지는 다른 수요기업에서도 인터뷰 진행 후 <br />
								프로젝트 참여 계약을 체결할 수 있으므로 인재확보의 우선권과 관계가 없습니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">관심인재 등록 및 삭제는 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_05_01.png">
							</div>
							<br />
							<p class="text_type_01 ta-center mb-10">
								프로젝트를 등록한 수요기업에서 해당 프로젝트에 참여를 지원한 기술인재 정보를 조회하는 화면입니다. <br />
								지원한 기술인재를 선택하면 해당 기술인재에 대한 상세보기 화면을 통하여 기술 및 평가등급, <br />
								수행 프로젝트 정보를 확인할 수 있으며, 해당 화면에서 '인터뷰 요청' 등록을 할 수 있습니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">관심인재 등록 후 인터뷰 요청을 하지 않아도 불이익이 없을까요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								네. 인터뷰를 요청하지 않으면 기술인재 본인에게 영향이 없습니다.<br />
                                단, 기술인재는 관심인재로 등록한 수요기업을 확인할 수 있습니다. 
							</p>
						</div>
						<!--//05 관심인재 등록 -->

						<!--06 기술인재 인터뷰 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '6'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 정보는 어떻게 확인할 수 있나요? </strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="14"/>1) 새로운 기술인재를 발굴하려면 '기술인재 찾기' 메뉴의 검색필터 조건에 해당하는 기술인재에 대하여 <br />
								<nbsp n="18"/>상세정보를 확인할 수 있습니다.<br />
								<nbsp n="13"/>2) 기존 프로젝트에 참여 또는 인터뷰 요청중인 기술인재 정보를 확인할 경우에는 <br />
								<nbsp n="18"/>'내프로젝트'의 '참여인재'로 등록된 기술인재 정보를 조회하면 됩니다. <br />
								<nbsp n="13"/>3) 하이프로 평가등급(별표)은 하이프로 자체평가 결과와 프로젝트를 통한 수행사의 평가 결과를 종합하여 <br />
								<nbsp n="18"/>산정하므로 프로젝트 참여 기회가 없는 경우 등급은 미평가로 표시됩니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재에게 인터뷰 요청은 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_06_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/syo_06_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-center mb-10">
								프로젝트를 등록한 수요기업은 검색한 기술인재 상세보기 화면에서 '프로젝트 인터뷰 요청'을 선택한 후 <br />
								인터뷰 대상 프로젝트 정보를 입력하고 기술인재에게 인터뷰를 요청합니다. <br />
								인터뷰 요청 정보는 하이프로의 프로젝트 담당매니저에게 전달되어 기술인재와 수요기업간 <br />인터뷰 가능일자를 조율한 후 
								인터뷰 진행을 도와줍니다. <br />
								이 때 매니저는 프로젝트관리 화면의 '기술인재 상태변경' 등록으로 인터뷰 일정 등 인터뷰 요청 사실을 등록합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재가 프로젝트 참여중일 때에도 인터뷰 요청을 할 수 있나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								네. 할 수 있습니다. <br />
								단, 진행중인 프로젝트가 있으므로 인터뷰 일정 및 중도 철수 등의 상황에 대한 관리를 위하여 <br />
								하이프로 매니저와 협의하여 진행하도록 하는 것이 좋습니다. <br />
								인터뷰 요청정보는 해당 프로젝트를 담당하는 하이프로 매니저가 함께 공유함으로써 <br />
								건전한 프로젝트 관리가 되도록 조율합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰 요청 결과 부적합 인력인 경우 처리방법은?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								수요기업에서 인터뷰 요청한 경우 프로젝트 담당매니저가 사전 인터뷰를 진행합니다.<br />
								그 결과 해당 프로젝트에 부적합한 경우 매니저가 수요기업 프로젝트 담당자에게 '인재 반려 및 교체 제안'의견을 전달하면	<br />
								프로젝트 담당자의 수용여부 선택에 따라 교체 또는 인터뷰를 진행하게 됩니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰 결과는 기술인재에게 어떻게 전달하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								수요기업은 인터뷰 결과를 하이프로 담당매니저에게 전달(유선, 이메일 등)하고, <br />
								매니저는 해당 인터뷰 결과를 프로젝트관리 화면에서 '기술인재 상태변경' 입력으로 등록합니다.
							</p>
						</div>
						<!--//06 기술인재 인터뷰 -->

						<!--07 계약 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '7'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트에 참여한 기술인재와 관련한 계약은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="24"/>1) 프로젝트 참여가 확정된 사항(프로젝트 또는 기술인재 용역)에 대한 계약은 수요기업과 <br />
								<nbsp n="29"/>하이프로 프로젝트 담당매니저(실제 경영관리부)가 진행합니다. <br />
								<nbsp n="29"/>이때 하이프로에서는 계약 관련한 별도의 시스템 기능은 제공하지 않습니다. <br />
								<nbsp n="24"/>2) 수요기업이 공급기업을 프로젝트 참여업체로 지정한 프로젝트의 경우에는 <br />
								<nbsp n="29"/>하이프로가 계약에 관여하지 않고 양사가 직접 계약하시면 됩니다 <br />
								<nbsp n="29"/>이때 인터뷰, 프로젝트 관리 등 하이프로 지원서비스는 제공되지 않습니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 수행 도중 계약을 변경해야 할 경우 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로의 프로젝트 담당매니저와 협의하시면 됩니다. <br />
								필요한 경우 변경계약서 또는 추가계약서 등 조치방법을 알려드립니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">계약 및 결제업무는 어떻게 처리하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로는 모든 계약행위를 본사 경영관리부에서 수행하며, <br />
								필요시에만 프로젝트 담당매니저에게 실무를 위임하여 처리할 수 있습니다.  
							</p>
						</div>
						<!--//07 계약 -->

						<!--08 프로젝트 진행관리 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '8'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로에서 제공하는 프로젝트 진행관리는 무엇인가요?</strong>
							</div>
							<p class="text_type_01 ta-left">
								<nbsp n="45"/>하이프로에서 공급한 기술인재의 프로젝트 참여 계약 체결 후 <br />
								<nbsp n="45"/>다음과 같이 기술인재에 대한 하이프로의 지원활동을 진행합니다. <br />
								<nbsp n="45"/>- 사이트 특성에 맞는 복무 관련 사전 교육 (근태, 보안, 휴가 등) <br />
								<nbsp n="45"/>- 담당역할에 대한 수행 상황 확인 및 미흡 시 해소방안 지원 <br />
								<nbsp n="45"/>- 팀워크 저해요인 예방 등을 위한 정기적인 현장 모니터링 <br /><br />
                            
								<nbsp n="45"/>하이프로의 프로젝트 현장 지원활동은 제한적이므로 <br />
								<nbsp n="45"/>기술인재와 관련하여 상호간 적극적인 정보 공유 및 협력체계가 필요합니다. 
							</p>
						</div>
						<!--//08 프로젝트 진행관리 -->

						<!--09 프로젝트 종료 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '9'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 종료에 따른 조치사항이 별도로 있나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								당초 계약대로 프로젝트가 종료된 경우 별도의 조치는 필요하지 않습니다. <br />
								단, 프로젝트기간이 변경된 경우에는 프로젝트관리화면에서 종료일을 변경 등록하여 관리합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트에 참여했던 기술인재의 잔류가 필요한 때에는 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								하이프로의 프로젝트 매니저에게 관련 의견을 전달하시면 됩니다. <br />
								특별한 경우가 아니라면 계약서 작성으로 바로 지원 가능합니다.  
							</p>
						</div>
						<!--//09 프로젝트 종료 -->

						<!--10 기술인재 수행 평가 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '10'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트에 참여한 기술인재에 대한 수행 성과평가는 어떻게 해야 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_10_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/syo_10_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-left mb-10">
								<nbsp n="14"/>1) 종료된 프로젝트 참여 기술인재에 대한 평가입니다. <br />
								<nbsp n="19"/>평가 등록 시 하이프로는 수요기업에 보상 포인트를 적립합니다. <br />
								<nbsp n="14"/>2) MyPage > 수행완료 프로젝트 중 해당되는 프로젝트의 '참여인재'를 선택 후 '평가하기'를 클릭하여<br />
								<nbsp n="19"/>업무실적, 근무태도, 기술능력을 체크하고 평가의견을 기술합니다. <br />
								<nbsp n="14"/>3) 본 평가내용은 추후 프로젝트 수행 시 참고되므로 가급적 상세하고 정확하게 입력바랍니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">회사의 평가 결과 및 내용을 기술인재 본인이 볼수 있나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								업무실적, 근무태도, 기술능력 평가 결과에 대하여 이미지 형태의 득점 비율표(☆별표)만 확인할 수 있으며, <br />
								평가자 및 상세 항목별 득점과 검토의견은 공개하지 않습니다. <br />
								즉, 본인의 프로젝트 수행에 대한 평가수준 정도를 확인할 수 있습니다. 
							</p>
						</div>
						<!--//10 기술인재 수행 평가 -->

						<!--11 회원 탈퇴 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '11'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기업회원이 탈퇴하려면 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/syo_11_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/syo_11_02.png">
							</div>
							<br />
							<p class="text_type_01 ta-left mb-4">
								1. 기업회원 탈퇴 등록  <br />
								<nbsp n="4"/> 1) 회원은 하이프로 사이트를 통해서 서비스 이용계약의 해지(회원탈퇴)를 신청할 수 있으며 <br />
								<nbsp n="9"/> 하이프로는 이를 처리합니다. 이때 하이프로는 회원 본인에게 해지 신청 사실을 확인할 수 있습니다. <br />
								<nbsp n="9"/> - 'MyPage > 정보관리'에서 '회원탈퇴'를 선택한 후 비밀번호 재입력으로 확인 <br />
								<nbsp n="4"/> 2) 기업담당회원이 퇴사 등으로 탈회하거나 하이프로에 해지 요청 시 해당 회원의 성명, 휴대폰번호, 이메일 등 <br />
								<nbsp n="10"/>개인정보는 삭제됩니다. 단, 서비스 이용정보는 회원기업정보로서 별도 관리되므로 <br />
								<nbsp n="10"/>다른 담당자가 회원가입을 통하여 선임될 경우 정상적으로 기존 서비스 활동을 할 수 있습니다. <br />
								<nbsp n="4"/> 3) 회원탈퇴로 발생한 불이익에 대한 책임은 회원 본인에게 있습니다.
							</p>
							<br />
							<p class="text_type_01 ta-left mb-10">
								2. 하이프로의 해지 처리 <br />
								<nbsp n="4"/> 1) 하이프로는 기업회원이 본 약관을 위반한 경우 또는 다음 각호의 경우에 해당되는 경우 <br />
								<nbsp n="9"/>사전 동의없이 이용계약의 해지 또는 서비스 중지 조치를 취할 수 있습니다. <br />
								<nbsp n="9"/> ① 회원의 의무를 성실하게 이행하지 않았을 경우 <br />
								<nbsp n="9"/> ② 회원이 허위 정보를 제공함으로써 회사에 손해를 끼치거나 건전한 서비스 이용에 나쁜 영향을 미친 경우 <br />
								<nbsp n="9"/> ③ 본 서비스의 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의를 일으킨 경우  <br />
								<nbsp n="9"/> ④ 본 서비스와 관련하여 회사 또는 타인의 명예를 훼손하거나 심각한 불이익을 초래한 경우<br />
								<nbsp n="9"/> ⑤ 기타 하이프로의 서비스에 위해를 가하는 행위를 한 경우<br />
								<nbsp n="4"/> 2) 하이프로는 위 (1)항의 사유로 이용계약이 해지된 회원이 사이트에 게시한 정보에 대하여 <br />
								<nbsp n="10"/>해당 서비스의 중단 및 정보를 임의 삭제할 수 있습니다. <br />
								<nbsp n="4"/> 3) 이용계약 해지에 따라 발생한 불이익에 대해서 하이프로는 책임을 부담하지 않습니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">회원 탈퇴 처리가 완료되면 통보해 주나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								네. 최종적으로 탈퇴 처리된 사항을 이메일 등을 통하여 수요기업의 총괄관리자에게 통보해 드립니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">탈퇴한 회원이 재가입하려면 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left">
								<nbsp n="20"/>수요기업이 탈퇴 후 재가입하고자 할 경우, <br />
								<nbsp n="20"/>① 하이프로는 해당 수요기업의 ‘관리총괄’ 담당자의 회원가입 승인 요청에 대하여 <br />
								<nbsp n="25"/>승인 여부를 검토한 후 하이프로 서비스 사용 권한을 부여할 수 있습니다. <br />
								<nbsp n="20"/>② ‘관리총괄’에 대한 회원가입 승인이 완료되면 ‘프로젝트 담당’ 회원 가입이 가능하게 되므로, <br />
                                <nbsp n="25"/>프로젝트 등록 등 정상적인 수요기업 회원으로서 활동이 가능합니다. <br /><br /> 
							</p>
						</div>
						<!--//11 회원 탈퇴 -->
					</div>
				</div>

				<!-- 기술인재 -->
				<div class="tab_cont" :class="tabIdx == '1' ? 'active' : ''">
					<div class="tab_cont_inner">
						<div class="cont_nav">
							<ul class="cont_nav_list">
								<li v-for="(item, index) in tecItemList" :key="index" :class="itemIdx == index ? 'active' : ''">
									<a href="javascript:void(0);" @click="itemIdxCng(index)">{{item}}</a>
								</li>
							</ul>
						</div>
						<!-- <div class="cont_detail pl-10">
							<div class="ta-center mt-6 mb-7"><strong class="text_type_title">기술인재 회원은 누구나 가입할 수 있나요?</strong></div>
							<div class="cont_image">
								<img src="/images/img_useguide_01.png" alt="프로엔 공통사항 화면 캡쳐" />
							</div>
							<div class="text_type_01 ta-center mt-10">
								내용 텍스트 입력 시 text_type_01 class를 사용 합니다. <br />
								ta-center = text-align : center <br />
								ta-left = text-align : left <br />
								mt-10 = margin-top : 40px 입니다. <br />
							</div>
							<div class="text_type_01 ta-left mt-4">
								m = margin <br />
								p = padding <br />
								t = top / r = right / b = bottm / l = left <br />
								ex) mt-1 = margin-top : 4px <br />
								단위는 4px 단위로 이루어져있습니다.
							</div>
						</div> -->

						<!--00 공통사항 -->
						<div class="cont_detail pl-10 pr-10" v-if="itemIdx == '0'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로 인재관리 시스템은 성공적인 프로젝트를 위하여 IT인재에 대한 평가와 검증 및 관리를 통하여 검증된<br />
								IT전문인재들을 기업의 프로젝트와 연결해 주는 온라인 인재관리 플랫폼입니다. <br /><br />

								즉, 용역을 제공하는 IT전문인력(프리랜서 또는 공급업체 기술인력)과 IT인력으로부터 용역을 공급받아 <br />
								프로젝트를 수행하고자 하는 수요기업 및 이들을 매칭해 주는 운영시스템인 하이프로로 구성된 플랫폼을 의미합니다. <br /><br />

								자세한 사항은 하이프로의 약관을 참조하시기 바랍니다.
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스의 프로세스는 어떻게 되나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								기술인재, 수요기업, 공급기업간 서비스 연계는 하이프로를 통하여 진행합니다.<br />
								각 주체간에는 계약을 체결함으로써 정보 및 용역서비스가 제공되며, 하이프로의 프로젝트별 담당매니저가 <br />
								각 주체간 중개, 중재, 관리 및 계약을 지원합니다. <br /><br />

								하이프로 서비스의 원활한 이용을 위해서는 담당매니저와의 적극적인 커뮤니케이션이 많은 도움을 드릴 것입니다. 
							</p>
                            <br />
							
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로는 회원의 개인정보를 어떻게 보호하고 있나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="15"/>1) 하이프로는 회원의 개인정보보호를 위하여 노력합니다.<br />
								<nbsp n="15"/>2) 회원의 개인정보보호에 대해서는 관련법령 및 회사가 별도로 고지하는 개인정보처리방침이 적용됩니다.<br />
								<nbsp n="15"/>3) 회원의 탈퇴 시에는 원칙적으로 즉시 개인정보를 완전히 삭제합니다.<br /><nbsp n="20"/>상세한 내용은 하이프로의 개인정보처리방침을 참고하시기 바랍니다.<br />
								<nbsp n="15"/>4) 회사는 회원 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 개인정보, 비밀정보에 대해서는<br /><nbsp n="20"/>책임을 부담하지 않습니다.<br />
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스의 My Page를 이용하시면 편리합니다.</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="30"/>회원 가입 후 My Page를 이용하면 사용이 훨씬 편리해집니다.<br />
								<nbsp n="30"/>① 프로젝트 상세조회 : 수행중 / 수행예정/ 완료 프로젝트 상세 조회<br />
								<nbsp n="30"/>② 관심인재 등록 정보<br />
								<nbsp n="30"/>③ 프로젝트 지원자 정보 <br />
								<nbsp n="30"/>④ 프로젝트 종료 후 미평가자 정보 <br />
								<nbsp n="30"/>⑤ 프로젝트 인터뷰 요청 상태  등 <br />
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스 사용이 가능한 시간은?</strong>
							</div>
							<p class="text_type_01 ta-center">
								원칙적으로 24시간 365일 사용 가능합니다.<br />
								단, 매일 23:00~익일 04:00까지는 시스템 작업 관계로 일부 서비스가 제한될 수 있습니다.<br /><br />

								하이프로 고객센터 운영 : 평일 09:30~17:30 (토,일,공휴일은 휴무)
							</p>
						</div>
						<!--//00 공통사항 -->

						<!--01 회원가입 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '1'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 회원가입은 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_02.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_03.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_04.png">
							</div>
							<br />
							
							<p class="text_type_01 ta-left mb-10">
								1) 회원유형 선택 : 하이프로 초기화면의 회원가입 메뉴에서 '기술인재 가입하기'를 선택합니다. <br />
								2) 약관동의 : 하이프로 약관 및 개인정보 수집 및 이용에 동의합니다. <br />
								<nbsp n="4"/> 동의하지 않는 경우 정상적인 하이프로 서비스를 제공할 수 없습니다. <br />
								3) 본인인증 : 휴대폰 인증으로 진행합니다. <br />
								4) 정보입력 : 회원 관련 정보를 입력하여 완료 합니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술력 평가를 위하여 어떤 증빙서류를 제출하여야 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								기술인재가 프로젝트에 지원하기 위해서는 다음 정보의 입력 및 관련 증빙을 등록하여 기술등급을 확정하여야 합니다. <br />
								- 등록대상 정보 : 이력서, 경력, 학력, 자격, 수상경력 등<br />
								- 증빙서류 등록 : KOSA증빙, 건강보험자격득실확인서, 졸업증명서, 자격증취득확인서, 기술경력증명서 <br /><br />

								위의 정보를 등록하지 않을 경우 기술인재에 대한 평가가 불가능하여 프로젝트 지원 등 서비스 이용이 제한됩니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 회원은 누구나 가입할 수 있나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								네. 개인은 누구나 하이프로 회원으로 가입할 수 있습니다.<br />
								단, 가입 후 개인이력정보 등 기술등급 산정을 위한 정보를 등록하지 않을 경우 프로젝트 지원 등 서비스 이용이 제한됩니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">소속사 등록 및 승인 절차는 어떻게 되나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_05.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_06.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_01_07.png">
							</div>
							<br />

							<p class="text_type_01 ta-center">
								회원가입 완료 후 로그인하시면, 이력서 및 소속사 등록 요청 팝업이 생성됩니다.<br />
								그 중, 소속사 등록 영역을 클릭하시면, 등록화면으로 연결됩니다.<br />
								소속사에는 기술인재가 실제 소속된 원소속사가 있으며,<br />
								프로젝트 참여시에 필요한 프로젝트 참여소속사가 있습니다.<br /><br />
								소속사는 하이프로 시스템에서 해당 소속사로 등록된 기술인재 현황을 조회할 수 있습니다.<br /><br />
								하이프로를 이용한 프로젝트 지원 시에는 원소속사 및 참여소속사를 확인하시기 바랍니다.<br />
								원소속사 변경 시에는 반드시 먼저 기존 소속사에 원소속사 탈퇴를 요청 하여야 합니다.<br />
								원소속사 변경이 안될 경우 수요기업과의 계약은 기존 소속사에서 처리하게 됩니다.<br /><br />
							</p>
						</div>
						<!--//01 회원가입 -->

						<!--02 기술인재 경력관리 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '2'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">경력관리를 하면 어떤 좋은점이 있을까요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								기술인재의 경력사항은 평가등급과 직접적인 관련이 되며 본인의 급여액을 결정하게 되는 객관적 근기가 됩니다. <br />
								또한 프로젝트 지원 시 1차적으로 참고가 되는 정보이므로 현행화가 반드시 필요합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재가 본인의 경력 및 교육사항 등 평가와 관련한 정보를 업데이트 할 경우<br />포인트가 지급되나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/ks_02_01.png">
							</div>
							<br />
							<p class="text_type_01 ta-center">
								네. 포인트가 지급됩니다. <br />
								신규 프로젝트의 착수 및 종료에 대한 정보 등록과 본인 능력 개발을 위한 교육 참가 및 자격증 획득 사실 등을<br /> 
								신규로 등록할 경우 약관에서 정한 소정의 금전적 포인트를 제공합니다. <br />
								신규로 NCS직무 자가평가를 진행한 경우에도 포인트를 지급합니다. 
							</p>
						</div>
						<!--//02 기술인재 경력관리 -->

						<!--03 프로젝트 지원 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '3'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 참여를 위한 지원은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								먼저 하이프로 기술인재 회원에 가입하여야 하며, 기술력 평가를 위하여 이력정보 및 증빙자료를 제출하여야 합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재 회원은 누구나 프로젝트에 지원이 가능한가요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								네. 기술인재 모집중인 착수 예정 또는 진행중인 프로젝트에 지원이 가능합니다. <br />
								지원한 프로젝트에 대하여 수요기업에서는 기술인재정보를 참고하여 인터뷰를 요청하게 되며,<br />
								하이프로 담당매니저가 인터뷰 일정을 조율하여 기술인재 정보에 인터뷰 일정을 등록하면<br />
								기술인재는 '프로젝트 인터뷰' 화면에서 인터뷰 일정을 확인할 수 있습니다. 
							</p>
						</div>
						<!--//03 프로젝트 지원 -->

						<!--04 인터뷰 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '4'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰가 무엇인가요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								기술인재와 기술인재를 필요로 하는 수요기업이 접촉하여(대면/비대면) 프로젝트범위와 요건 및<br />
								기술인재의 수행역량에 대하여 협의하고 상호 점검하는 프로세스입니다. 
							</p>
							<br />
							
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰 진행 절차는 어떻게 되나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-4">
								<nbsp n="35"/>인터뷰는 기술인재와 수요기업의 접촉채널입니다. <br />
								<nbsp n="35"/>인터뷰를 위한 사전 활동으로서, <br />
								<nbsp n="35"/>1) 기술인재   <br />
								<nbsp n="35"/> ① 기술인재 : 모집중인 프로젝트에 지원 등록  <br />
								<nbsp n="35"/> ② 수요기업 : 프로젝트 지원자 검색하여 인터뷰 요청 등록 (일반 프로젝트만 해당)  <br />
								<nbsp n="35"/> ③ 하이프로 프로젝트 담당매니저 : 인터뷰 요청일 등록 (일반 프로젝트만 해당)  <br />
								<nbsp n="35"/> ④ 기술인재 : 프로젝트 인터뷰 진행상황 조회 (일반 프로젝트만 해당)  <br />
								<nbsp n="35"/> ⑤ 담당매니저 : 기술인재의 수요기업 인터뷰 진행 지원 (일반 프로젝트만 해당)  <br />
								<nbsp n="35"/> ⑥ 수요기업 : 하이프로 프로젝트 담당매니저에 인터뷰 결과 통보 (일반 프로젝트만 해당)   <br />
								<nbsp n="35"/> ⑦ 담당매니저 : 기술인재 화면에 인터뷰 결과 등록   <br />
								<nbsp n="35"/> ⑧ 기술인재 : 인터뷰 결과 조회 확인   <br />
							</p>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="35"/>2) 수요기업   <br />
								<nbsp n="35"/> ① 프로젝트의 지원인재 확인 및 관심인재 등록, 인터뷰 요청 등록 (일반 프로젝트만 해당)  <br />
								<nbsp n="35"/> ② 하이프로 프로젝트 담당매니저에게 인터뷰 일정 통보 (일반 프로젝트만 해당) <br />
								<nbsp n="35"/> ③ 인터뷰 실시 <br />
								<nbsp n="35"/> ④ 인터뷰 결과 프로젝트 담당매니저에게 통보 (일반 프로젝트만 해당)
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰 결과는 어떻게 확인할 수 있나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/ks_04_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-center mb-10">
								인터뷰 결과는 수요기업에서 하이프로 담당매니저에게 결과를 전달(일반 프로젝트만 해당)하여<br />
								기술인재에게 '내프로젝트 > 프로젝트 인터뷰' 화면을 통하여 최종 전달됩니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">인터뷰에 합격하였으나 프로젝트에 참여할 수 없게 된 경우에는 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								하이프로 담당매니저에게 즉시 참여 불가 사유를 설명하여 수요기업에 통보되도록 해야 합니다. <br />
								매니저는 기술인재의 프로젝트 참여 의사의 잦은 번복 및 불참 사유에 대한 고의성 여부를 검토한 후 향후 프로젝트 지원을 제한할 수도 있으므로 참여 의사 번복에 유의하여야 합니다. 
							</p>
						</div>
						<!--//04 인터뷰 -->

						<!--05 계약 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '5'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 참여에 대한 계약은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								프로젝트 참여가 확정된 사항(프로젝트 또는 기술인재 용역)에 대한 계약은 기술인재 또는<br />
								기술인재를 공급한 업체와 하이프로의 프로젝트 담당매니저가  진행합니다. <br />
								하이프로에서는 계약 관련한 별도의 시스템 기능은 제공하지 않으며,<br />
								프로젝트 담당매니저와 협의하여 진행하면 편리합니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 수행 도중 계약을 변경해야 할 경우 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로의 프로젝트 담당매니저와 협의하시면 됩니다. <br />
								필요한 경우 변경계약서 또는 추가계약서 등 조치방법을 알려드립니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">계약 및 결제업무는 어떻게 처리하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								하이프로는 모든 계약행위를 본사 경영관리부에서 수행하며,<br />
								필요시에만 프로젝트 담당매니저에게 실무를 위임하여 처리할 수 있습니다.
							</p>
						</div>
						<!--//05 계약 -->

						<!--06 프로젝트 수행 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '6'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 참여 전에 고려해야 할 사항은 무엇인가요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="4"/>1) 기술인재의 프로젝트 참여 전에 하이프로의 프로젝트 담당매니저가 프로젝트 개요 및<br /> 
								<nbsp n="9"/>사이트에 대한 복무지침 등을 알려드립니다. <br />
								<nbsp n="4"/>2) 기술인재는 담당 과제를 수행하기 위한 기술력 및 업무지식 등을 사전에 검토하여 <br />
								<nbsp n="9"/>프로젝트에 차질이 없도록 해야 하며, 검토 결과 본인이 수행 불가능한 업무인 경우에는 <br />
								<nbsp n="9"/>프로젝트 매니저에게 사전에 의사표현을 하여야 합니다.<br />
								<nbsp n="4"/>3) 하이프로는 기술인재의 역량 강화를 위한 상담 및 금융업무에 대한 사전 교육을 제한적으로 지원할 수 있습니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 수행 시 하이프로는 어떤 지원활동을 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="4"/>1) 하이프로가 프로젝트 도급 계약을 수행하는 경우에는 프로젝트 리더 및 분야별 필요 전문가를 투입하여<br />
								<nbsp n="9"/>책임 수행하게 되므로 담당영역에 대하여 전반적인 진행관리를 지원합니다. <br />
								<nbsp n="4"/>2) 개별 기술인재가 투입된 단순 용역계약인 경우에는 프로젝트 진행 시 발생 이슈 및 지원이 필요한 사항에 대하여<br />
								<nbsp n="9"/>수요기업 또는 주사업자와 협의하여 지원할 수 있습니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 진행상황을 매니저에게 보고해야 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								하이프로 프로젝트 담당매니저는 필요에 따라 프로젝트 진행상황을 관리하게 됩니다. <br />
								프로젝트에 참여한 기술인재는 비공식적이더라도 프로젝트 관련 이슈 및 진행과 관련한 주요사항을 담당매니저에게 전달하여 성공적인 프로젝트가 될 수 있도록 주의를 기울여 주시기 바랍니다. 
							</p>
						</div>
						<!--//06 프로젝트 수행 -->

						<!--07 프로젝트 종료 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '7'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">기술인재의 프로젝트 종료 절차는?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="25"/>1) 프로젝트 후반(테스트단계, 이행단계 등)에 최종 확정된 프로젝트 종료 및 철수일을<br />
								<nbsp n="30"/>하이프로 프로젝트 담당매니저에게 알립니다.<br />
								<nbsp n="25"/>2) 본 프로젝트 종료 직후 타 프로젝트에 참여하고자 할 경우, 하이프로 플랫폼에 접속하여<br />
								<nbsp n="30"/>신규 프로젝트에 지원 등록합니다.<br />
								<nbsp n="25"/>3) 수요기업의 인터뷰 요청 시 담당매니저의 일정 조율에 따라 인터뷰에 응합니다. <br />
								<nbsp n="25"/>4) 프로젝트 종료 후 철수와 함께 하이프로 이력정보를 갱신합니다.
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">프로젝트 종료 시에 반드시 수행해야 할 일은?</strong>
							</div>
							<p class="text_type_01 ta-center">
								기술인재는 원칙적으로 할당된 프로젝트 과제를 계획대로 완료한 후에는 반드시 해당 결과에 대한 인수인계와 <br />
								수요기업의 최종 승인이 완료(검수)된 후에 철수가 가능합니다. <br />
								고객이 산출물에 대하여 불승인할 경우에는 계약내용대로 용역이 제공되지 않은 것으로 판단하여 <br />
								하이프로 및 기술인재에게 불이익을 초래할 수 있으므로 철저한 마무리가 필요합니다. <br />
								단, 공식적인 인수인계 및 승인 절차가 필요없다면 수요기업 및 프로젝트 리더의 철수절차에 따라 대응하면 됩니다. 
							</p>
						</div>
						<!--//07 프로젝트 종료 -->

						<!--08 회원 탈퇴 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '8'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 회원을 탈퇴하려면 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/ks_08_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/ks_08_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-left mb-4">
								1. 기술인재의 회원 탈퇴 등록  <br />
								<nbsp n="4"/>1) 회원은 하이프로 사이트를 통해서 서비스 이용계약의 해지(회원탈퇴)를 신청할 수 있으며, 하이프로는 이를 처리한 후<br />
								<nbsp n="9"/>회원 본인에게 해지 신청 사실을 확인할 수 있습니다. <br />
								<nbsp n="9"/> - 'MyPage > 개인정보관리'에서 '회원탈퇴'를 선택한 후 비밀번호 재입력으로 확인<br />
								<nbsp n="4"/>2) 회원탈퇴로 발생한 불이익에 대한 책임은 회원 본인에게 있습니다.<br />
							</p>
							<p class="text_type_01 ta-left mb-10">
								2. 하이프로의 해지 처리 <br />
								<nbsp n="4"/>1) 하이프로는 회원이 본 약관을 위반한 경우 또는 다음 각호의 경우에 해당되는 경우 사전 동의없이<br />
								<nbsp n="9"/>이용계약의 해지 또는 서비스 중지 조치를 취할 수 있습니다. <br />
								<nbsp n="9"/>① 회원의 의무를 성실하게 이행하지 않았을 경우 <br />
								<nbsp n="9"/>② 회원이 허위 정보를 제공함으로써 회사에 손해를 끼치거나 건전한 서비스 이용에 나쁜 영향을 미친 경우 <br />
								<nbsp n="9"/>③ 본 서비스의 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의를 일으킨 경우 <br /> 
								<nbsp n="9"/>④ 본 서비스와 관련하여 회사 또는 타인의 명예를 훼손하거나 심각한 불이익을 초래한 경우<br />
								<nbsp n="9"/>⑤ 기타 하이프로의 서비스에 위해를 가하는 행위를 한 경우<br />
								<nbsp n="4"/>2) 하이프로는 위 (1)항의 사유로 이용계약이 해지된 회원이 사이트에 게시한 정보에 대하여<br />
								<nbsp n="9"/>해당 서비스의 중단 및 정보를 임의 삭제할 수 있습니다. <br />
								<nbsp n="4"/>3) 이용계약 해지에 따라 발생한 불이익에 대해서 하이프로는 책임을 부담하지 않습니다.<br />
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">회원 탈퇴 처리가 완료되면 통보해 주나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								네. 최종적으로 탈퇴 처리된 사항을 이메일 등을 통하여 기술인재에게 통보해 드립니다. 
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">탈퇴한 회원이 재가입하려면 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								1) 기술인재 회원이 탈퇴 후 재가입할 경우에는 신규회원 가입과 동일하게<br />
								<nbsp n="4"/>①약관 동의  ②본인 인증  ③회원정보 입력 ④이력정보 및 증빙서류 등록 후 하이프로 서비스 이용이 가능하게 됩니다. <br />
								<nbsp n="4"/> NCS직무 자가평가까지 등록하면 등급 평가 시 유리합니다. <br />
								2) 단, 반복적인 회원 가입 및 탈퇴 등 회원이 본 약관에서 정한 내용을 위반하거나 위배되는 활동을 한 경우<br />
								<nbsp n="4"/>하이프로는 회원 가입을 제한할 수 있으며, 회원의 사이트 접속 제한, 회원 게시정보의 삭제, 회원정보의 삭제 등을<br />
								<nbsp n="4"/>임의로 진행할 수 있습니다.
							</p>
						</div>
						<!--//08 회원 탈뢰 -->
					</div>
				</div>

				<!-- 공급기업 -->
				<div class="tab_cont" :class="tabIdx == '2' ? 'active' : ''">
					<div class="tab_cont_inner">
						<div class="cont_nav">
							<ul class="cont_nav_list">
								<li v-for="(item, index) in scoItemList" :key="index" :class="itemIdx == index ? 'active' : ''">
									<a href="javascript:void(0);" @click="itemIdxCng(index)">{{item}}</a>
								</li>
							</ul>
						</div>
						<!-- <div class="cont_detail pl-10">
							<div class="ta-center mt-6 mb-7"><strong class="text_type_title">하이프로 플랫폼에서 공급기업이란?</strong></div>
							<div class="cont_image">
								<img src="/images/img_useguide_01.png" alt="프로엔 공통사항 화면 캡쳐" />
							</div>
								<p class="text_type_01 ta-center mt-10">
									수요기업이 하이프로를 사용하기 위해서는 본 서비스를 총괄하는 관리총괄담당자와 개별 프로젝트를 수행하는 <br />
									프로젝트담당자가 회원으로 우선 가입하여야 합니다.
							</p>
						</div> -->

						<!--00 공통사항 -->
						<div class="cont_detail pl-10 pr-10" v-if="itemIdx == '0'">
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로 인재관리 시스템은 성공적인 프로젝트를 위하여 IT인재에 대한 평가와 검증 및 관리를 통하여 검증된<br />
								IT전문인재들을 기업의 프로젝트와 연결해 주는 온라인 인재관리 플랫폼입니다. <br /><br />

								즉, 용역을 제공하는 IT전문인력(프리랜서 또는 공급업체 기술인력)과 IT인력으로부터 용역을 공급받아 <br />
								프로젝트를 수행하고자 하는 수요기업 및 이들을 매칭해 주는 운영시스템인 하이프로로 구성된 플랫폼을 의미합니다. <br /><br />

								자세한 사항은 하이프로의 약관을 참조하시기 바랍니다.
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스의 프로세스는 어떻게 되나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								기술인재, 수요기업, 공급기업간 서비스 연계는 하이프로를 통하여 진행합니다.<br />
								각 주체간에는 계약을 체결함으로써 정보 및 용역서비스가 제공되며, 하이프로의 프로젝트별 담당매니저가 <br />
								각 주체간 중개, 중재, 관리 및 계약을 지원합니다. <br /><br />

								하이프로 서비스의 원활한 이용을 위해서는 담당매니저와의 적극적인 커뮤니케이션이 많은 도움을 드릴 것입니다. 
							</p>
                            <br />
							
							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로는 회원의 개인정보를 어떻게 보호하고 있나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="15"/>1) 하이프로는 회원의 개인정보보호를 위하여 노력합니다.<br />
								<nbsp n="15"/>2) 회원의 개인정보보호에 대해서는 관련법령 및 회사가 별도로 고지하는 개인정보처리방침이 적용됩니다.<br />
								<nbsp n="15"/>3) 회원의 탈퇴 시에는 원칙적으로 즉시 개인정보를 완전히 삭제합니다.<br /><nbsp n="20"/>상세한 내용은 하이프로의 개인정보처리방침을 참고하시기 바랍니다.<br />
								<nbsp n="15"/>4) 회사는 회원 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 개인정보, 비밀정보에 대해서는<br /><nbsp n="20"/>책임을 부담하지 않습니다.<br />
							</p>
                            <br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스의 My Page를 이용하시면 편리합니다.</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="30"/>회원 가입 후 My Page를 이용하면 사용이 훨씬 편리해집니다.<br />
								<nbsp n="30"/>② 프로젝트 상세조회 : 수행중 / 수행예정/ 완료 프로젝트 상세 조회<br />
								<nbsp n="30"/>③ 관심인재 등록 정보<br />
								<nbsp n="30"/>④ 프로젝트 지원자 정보 <br />
								<nbsp n="30"/>⑤ 프로젝트 종료 후 미평가자 정보 <br />
								<nbsp n="30"/>⑥ 프로젝트 인터뷰 요청 상태  등 <br />
							</p>
							<br />

							<div class="ta-center mt-6 mb-7">
								<strong class="text_type_title">하이프로 서비스 사용이 가능한 시간은?</strong>
							</div>
							<p class="text_type_01 ta-center">
								원칙적으로 24시간 365일 사용 가능합니다.<br />
								단, 매일 23:00~익일 04:00까지는 시스템 작업 관계로 일부 서비스가 제한될 수 있습니다.<br /><br />

								하이프로 고객센터 운영 : 평일 09:30~17:30 (토,일,공휴일은 휴무)
							</p>
						</div>
						<!--//00 공통사항 -->

						<!--01 회원가입 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '1'">
							<div class="ta-center mb-8">
								<strong class="text_type_title">하이프로 플랫폼에서 공급기업이란?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								공급기업은 IT기술인재를 보유하고 있는 회사로서 수요기업에 이미 기술인재를 공급하고 있거나<br />
								새롭게 프로젝트에 지원하는 기술인재의 소속회사입니다. 
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">하이프로 서비스는 어떻게 이용할 수 있나요? <br />(하이프로 서비스 이용계약 체결)</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								먼저 하이프로와 공급기업간 '서비스 이용계약'을 체결해야 합니다. 서비스 이용계약은 별도의 계약서를 통하여 진행하며, 계약 시 제공된 기업정보를 하이프로 운영팀에서 시스템에 등록하여 기업실무담당자가 회원으로 가입할 수 있도록 합니다. <br />
								공급기업의 제공정보는 사업자등록증, 직원수, 홈페이지 정보 등입니다.
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">하이프로 서비스 사용료는 얼마이며 결제는 언제 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="12"/>1) 하이프로 서비스 내용 <br />
								<nbsp n="14"/> - 공급기업이 소속 기술인재를 하이프로에 위탁 관리 <br />
								<nbsp n="16"/>   (정보관리, 프로젝트 참여, 인터뷰, 프로젝트 관리, 평가관리 등)하는 경우 서비스 이용수수료 명목 <br />
								<nbsp n="12"/>2) 공급기업의 하이프로 서비스 사용료 <br />
								<nbsp n="14"/> - 하이프로와 수요기업간 용역계약 체결금액 X 10% <br />
								<nbsp n="12"/>3) 대금 정산 시기 <br />
								<nbsp n="14"/> - 용역대금 지급 시 <br /><br />
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">하이프로 회원가입 등록은 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/kk_01_01.png">
							</div>
							<br />

							<p class="text_type_01 ta-center mb-10">
								공급기업의 임직원이 회사를 대리하여 하이프로 서비스를 사용할 수 있도록<br />
								실무담당자가 개인명의 회원으로 가입하여야 합니다. <br />
								이는 기술인재의 관리 및 계약 등 실무처리와 효율적인 인력관리를 위한 하이프로의 운영기준입니다.  
							</p>
							<br />
						</div>
						<!--//01 회원가입 -->

						<!--02 공급기업 지정 프로젝트 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '2'">
							<div class="ta-center mb-8">
								<strong class="text_type_title">공급기업 지정 프로젝트가 뭔가요?</strong>
							</div>

							<p class="text_type_01 ta-center mb-10">
								공급기업 지정 프로젝트는 하이프로 매니저가 배정되지 않으며,<br />
								수요기업과 공급기업간 직접 거래하는 프로젝트 입니다. <br />
								수요기업에서 프로젝트 등록시 지정한 공급기업에서<br />
								프로젝트 상세 모집정보를 등록하여 기술인재를 모집하는 프로젝트 입니다.
							</p>
							<br /><br /><br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">공급기업 지정 프로젝트는 어떻게 등록하나요?</strong>
							</div>

							<p class="text_type_01 ta-center mb-10">
								<strong>- 마이페이지에서 이동하기 -</strong>
							</p>
							<div class="cont_image">
								<img src="/images/kk_02_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_02_03.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_02_04.png">
							</div>
							<br />
							<p class="text_type_01 ta-center mb-10">
								로그인을 하면 마이페이지에 자동으로 이동되며,<br />
								수요기업에서 프로젝트 등록요청한 건수를 클릭하면, 전체프로젝트 리스트 페이지로 이동합니다.<br />
								리스트중 등록요청 프로젝트의 '프로젝트 등록' 버튼을 클릭하면, 프로젝트 등록페이지로 이동합니다.
							</p>
                            <br /><br /><br />

                            <p class="text_type_01 ta-center mb-10">
								<strong>- 메뉴목록에서 이동하기 -</strong>
							</p>
							<div class="cont_image">
								<img src="/images/kk_02_02.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_02_03.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_02_04.png">
							</div>
							<br />
							<p class="text_type_01 ta-center mb-10">
								또는, 좌측 상단의 메뉴목록 아이콘을 클릭하면 전체 메뉴목록이 나타나며,<br />
								메뉴목록에서 '공급기업 지정 프로젝트 > 전체 프로젝트' 메뉴를 클릭하면, 전체프로젝트 리스트 페이지로 이동합니다.<br />
								리스트중 등록요청 프로젝트의 '프로젝트 등록' 버튼을 클릭하면, 프로젝트 등록페이지로 이동합니다.
							</p>
						</div>
						<!--//02 공급기업 지정 프로젝트 -->

						<!--03 소속사 인력현황 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '3'">
						    <div class="ta-center mb-8">
								<strong class="text_type_title">기술인재에 대한 어떠한 정보를 제공하나요?</strong>
							</div>

							<p class="text_type_01 ta-center mb-10">
								하이프로 회원으로 가입한 소속 기술인재정보를 조회할 수 있습니다. <br />
								기술인재에 대한 이력정보 및 평가정보 등 상세한 정보를 제공합니다.
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">프로젝트에 지원한 기술인재는 어떻게 확인할 수 있나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/kk_03_01.png">
							</div>
							<br />

							<p class="text_type_01 ta-center mb-10">
								우측 상단의 인물아이콘을 클릭한 후 'My Page' 의 회원으로 등록 또는<br />
								프로젝트에 참여중이거나 참여 예정인 소속사의 기술인재 정보와 상세정보를 확인할 수 있습니다. 
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">기술인재 상세정보에 대한 자세한 설명이 필요합니다.</strong>
							</div>
							<div class="cont_image">
								<img src="/images/kk_03_01_a.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_03_02.png">
							</div>
							<br />
							
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="45"/>공급기업 소속 회원에 대한 상세정보 확인이 가능합니다. <br />
								<nbsp n="45"/>> 기술인재 평가등급(수행사 평가, 하이프로 평가)<br />
								<nbsp n="45"/>> 기술등급 (학력, 자격)<br />
								<nbsp n="45"/>> NCS 자가평가 (직무/등급)<br />
								<nbsp n="45"/>> 전문분야, 직무분야, 보유기술, 수행완료 프로젝트 <br />
								<nbsp n="45"/>> 상세정보 : 자기소개, 학력, 자격증, 경력, 증빙서류, 수행사 평가 <br />
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">기술인재의 프로젝트 지원 및 인터뷰 일정관리는 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="16"/>1. 소속 기술인재가 하이프로를 이용하여 프로젝트에 지원하는 경우<br />
								<nbsp n="18"/>  -소속사(공급기업)와 기술인재가 사전에 프로젝트 지원 계획을 공유했다고 판단합니다.<br />
								<nbsp n="18"/>  -하이프로의 프로젝트 담당매니저가 인터뷰 진행 등을 지원하게 되며, <br />
								<nbsp n="18"/>   하이프로와 공급기업간 공급계약을 체결하시면 됩니다. <br />
								<nbsp n="16"/>2. 수요기업이 공급기업을 프로젝트 참여업체로 지정하여 하이프로 시스템을 이용하기로 한 경우<br />
								<nbsp n="18"/>  -프로젝트 지원 및 인터뷰 일정관리 등 기술인재 관리를 공급기업에서 직접 하셔야 합니다.<br />
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">기술인재의 소속사 변경 처리는 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="16"/>1. 기술인재의 프로젝트 참여 소속사는 수요기업 또는 하이프로와 <br />
								<nbsp n="20"/>   인력공급 계약 주체로서 계약을 체결하게 됩니다. <br />
								<nbsp n="16"/>2. 따라서 기술인재의 소속사가 변경될 경우 하이프로 시스템에서는 전소속사 및 신규 소속사에 <br />
								<nbsp n="20"/>   기술인재 소속 변경요청 사실을 통보하고, 각 사에 승인을 요청합니다. <br />
								<nbsp n="16"/>3. 소속사 변경에 대한 승인처리는 소속사와 기술인재가 직접 합의한 상태에서 진행하도록 권고합니다. <br />
							</p>
						</div>
						<!--//03 소속사 인력현황 -->

						<!--04 계약 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '4'">
							<div class="ta-center mb-8">
								<strong class="text_type_title">프로젝트에 참여한 기술인재와 관련한 계약은 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="20"/>1. 프로젝트 참여가 확정된 사항(프로젝트 또는 기술인재 용역)에 대한 계약은 공급기업과 <br />
								<nbsp n="24"/>   하이프로 프로젝트 담당매니저(실제 경영관리부)가 진행합니다. <br />
								<nbsp n="24"/>   이때 하이프로에서는 계약 관련한 별도의 시스템 기능은 제공하지 않습니다. <br />
								<nbsp n="20"/>2. 수요기업이 공급기업을 프로젝트 참여업체로 지정한 프로젝트의 경우에는 <br />
								<nbsp n="24"/>   하이프로가 계약에 관여하지 않고 양사가 직접 계약하시면 됩니다. <br />
								<nbsp n="24"/>   이때 인터뷰, 프로젝트 관리 등 하이프로 지원서비스는 제공되지 않습니다. <br />
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">프로젝트 수행 도중 계약을 변경해야 할 경우 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-center mb-10">
								하이프로의 프로젝트 담당매니저와 협의하시면 됩니다. <br />
								필요한 경우 변경계약서 또는 추가계약서 등 조치방법을 알려드립니다. 
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">계약 및 결제업무는 어떻게 처리하나요?</strong>
							</div>
							<p class="text_type_01 ta-center">
								하이프로는 모든 계약행위를 본사 경영관리부에서 수행하며,<br />
								필요시에만 프로젝트 담당매니저에게 실무를 위임하여 처리할 수 있습니다.
							</p>
						</div>
						<!--//04 계약 -->

						<!--05 프로젝트 진행관리 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '5'">
							<div class="ta-center mb-8">
								<strong class="text_type_title">프로젝트 진행 관련하여 공급기업 입장에서는 무엇을 해야 할까요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="4"/>1. 하이프로에서 수요기업과 프로젝트 계약을 체결하는 경우에는<br />
								<nbsp n="8"/> 다음과 같이 기술인재에 대한 하이프로의 지원활동을 진행합니다. <br />
								<nbsp n="8"/> - 프로젝트 담당매니저 지정<br />
								<nbsp n="8"/> - 사이트 특성에 맞는 복무 관련 사전 교육 (근태, 보안, 휴가 등)<br />
								<nbsp n="8"/> - 담당역할에 대한 수행 상황 확인 및 미흡 시 해소방안 지원<br />
								<nbsp n="8"/> - 팀워크 저해요인 예방 등을 위한 정기적인 현장 모니터링 <br /><br />

								<nbsp n="4"/>2. 수요기업과 공급기업이 직접 프로젝트 계약을 진행하는 경우에는 하이프로는 투입인력에 대하여 관리하지 않습니다.<br />
								<nbsp n="8"/> 단, 이때에는 공급기업에서 투입인력에 대한 매니저 역할을 수행하여 프로젝트를 지원할 필요가 있습니다. 
							</p>
						</div>
						<!--//05 프로젝트 진행관리 -->

						<!--06 회원 탈퇴 -->
						<div class="cont_detail pl-10 pr-10" v-else-if="itemIdx == '6'">
							<div class="ta-center mb-8">
								<strong class="text_type_title">기업회원이 탈퇴하려면 어떻게 하나요?</strong>
							</div>
							<div class="cont_image">
								<img src="/images/kk_06_01.png">
							</div>
							<div class="cont_image">
								<img src="/images/kk_06_02.png">
							</div>
							<br />

							<p class="text_type_01 ta-left mb-4">
								1. 기업회원 탈퇴 등록  <br />
								<nbsp n="4"/> 1) 회원은 하이프로 사이트를 통해서 서비스 이용계약의 해지(회원탈퇴)를 신청할 수 있으며 <br />
								<nbsp n="9"/> 하이프로는 이를 처리합니다. 이때 하이프로는 회원 본인에게 해지 신청 사실을 확인할 수 있습니다. <br />
								<nbsp n="9"/> - 'MyPage > 정보관리'에서 '회원탈퇴'를 선택한 후 비밀번호 재입력으로 확인 <br />
								<nbsp n="4"/> 2) 기업담당회원이 퇴사 등으로 탈회하거나 하이프로에 해지 요청 시<br />
								<nbsp n="9"/> 해당 회원의 성명, 휴대폰번호, 이메일 등 개인정보는 삭제됩니다. <br />
								<nbsp n="9"/> 단, 서비스 이용정보는 회원기업정보로서 별도 관리되므로<br />
								<nbsp n="9"/> 다른 담당자가 회원가입을 통하여 선임될 경우 정상적으로 기존 서비스 활동을 할 수 있습니다. <br />
								<nbsp n="4"/> 3) 회원탈퇴로 발생한 불이익에 대한 책임은 회원 본인에게 있습니다.
							</p>
							<p class="text_type_01 ta-left mb-10">
								2. 하이프로의 해지 처리 <br />
								<nbsp n="4"/> 1) 하이프로는 기업회원이 본 약관을 위반한 경우 또는 다음 각호의 경우에 해당되는 경우<br />
								<nbsp n="9"/> 사전 동의없이 이용계약의 해지 또는 서비스 중지 조치를 취할 수 있습니다. <br />
								<nbsp n="9"/> ① 회원의 의무를 성실하게 이행하지 않았을 경우 <br />
								<nbsp n="9"/> ② 회원이 허위 정보를 제공함으로써 하이프로에 손해를 끼치거나 건전한 서비스 이용에 나쁜 영향을 미친 경우 <br />
								<nbsp n="9"/> ③ 본 서비스의 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의를 일으킨 경우 <br />
								<nbsp n="9"/> ④ 본 서비스와 관련하여 하이프로 또는 타인의 명예를 훼손하거나 심각한 불이익을 초래한 경우 <br />
								<nbsp n="9"/> ⑤ 기타 하이프로의 서비스에 위해를 가하는 행위를 한 경우 <br />
								<nbsp n="4"/> 2) 하이프로는 위 (1)항의 사유로 이용계약이 해지된 회원이 사이트에 게시한 정보에 대하여<br />
								<nbsp n="9"/> 해당 서비스의 중단 및 정보를 임의 삭제할 수 있습니다. <br />
								<nbsp n="4"/> 3) 이용계약 해지에 따라 발생한 불이익에 대해서 하이프로는 책임을 부담하지 않습니다.
							</p>
							<br />

							<div class="ta-center mb-8">
								<strong class="text_type_title">탈퇴한 회원이 재가입하려면 어떻게 하나요?</strong>
							</div>
							<p class="text_type_01 ta-left mb-10">
								<nbsp n="4"/>기업회원이 탈퇴 후 재가입할 경우에는 신규회원 가입과 동일하게 <br /> 
								<nbsp n="4"/>①기업간 서비스 이용계약 체결 <br /> 
								<nbsp n="4"/>②기업정보 등록 <br /> 
								<nbsp n="4"/>③기업담당회원의 회원가입 후 하이프로 서비스 이용이 가능하게 됩니다. <br />
							</p>
						</div>
						<!--//06 회원 탈퇴 -->
					</div>
				</div>
			</div>
		</div>

		<button type="button" class="btn-close" @click="$emit('close')">닫기</button>
	</div>
</template>

<script>
export default {
	props: {
		param : Object,
	},

	data() {
		return {
			tabIdx : '0',
			itemIdx : '0',
			faqTabList : [
				'수요기업',
				'기술인재',
				'공급기업'
			],
			dcoItemList : [
				'00 공통사항',
				'01 회원가입',
				'02 프로젝트 등록',
				'03 기술인재 찾기',
				'04 프로젝트 지원인재',
				'05 관심인재 등록',
				'06 기술인재 인터뷰',
				'07 계약',
				'08 프로젝트 진행관리',
				'09 프로젝트 종료',
				'10 기술인재 수행 평가',
				'11 회원 탈퇴'
			],
			tecItemList : [
				'00 공통사항',
				'01 회원가입',
				'02 기술인재 경력관리',
				'03 프로젝트 지원',
				'04 인터뷰',
				'05 계약',
				'06 프로젝트 수행',
				'07 프로젝트 종료',
				'08 회원 탈퇴'
			],
			scoItemList : [
				'00 공통사항',
				'01 회원가입',
				'02 공급기업 지정 프로젝트',
				'03 소속사 인력현황',
				'04 계약',
				'05 프로젝트 진행관리',
				'06 회원 탈퇴'
			]
		}
	},

	beforeMount(){
		if(this.param.tabIdx) {
			this.tabIdx = this.param.tabIdx;
		 	this.itemIdx = this.param.itemIdx;
		}
	},

	methods: {
		tabIdxCng(idx) {
			this.tabIdx = idx;
			this.itemIdx = '0';
		},

		itemIdxCng(idx) {
			this.itemIdx = idx;
		}
	}
}
</script>